'use client';
import MoshLogo from '../../MoshLogo/MoshLogo';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { IconButton } from '@get-mosh/msh-shared';
import Link from 'next/link';
import classNames from 'classnames';
import Routes from 'src/constants/Routes';

export type BasicHeaderProps = {
    sticky?: boolean;
    logoLink?: string;
    backLink?: string;
    darkMode?: boolean;
    border?: boolean;
};

const BasicHeader = ({
    sticky = false,
    logoLink = Routes.HOME,
    backLink,
    darkMode = false,
    border = false,
}: BasicHeaderProps) => {
    return (
        <header
            className={classNames('relative z-50', {
                'bg-green-dark': darkMode,
                'bg-white': !darkMode,
                'sticky top-0': sticky,
                'border-b border-grey-200': border,
            })}
        >
            <div className="flex h-full items-center justify-center p-4">
                {backLink ? (
                    <div className="absolute left-0">
                        <IconButton
                            as={Link}
                            icon={faChevronLeft}
                            variant={darkMode ? 'tertiaryLight' : 'tertiary'}
                            href={backLink}
                            className={classNames({
                                'text-white': darkMode,
                                'text-green-dark': !darkMode,
                            })}
                        />
                    </div>
                ) : null}

                <div className="flex flex-1 justify-center">
                    <MoshLogo
                        link={logoLink}
                        variant={darkMode ? 'light' : 'dark'}
                        label="Home"
                    />
                </div>
            </div>
        </header>
    );
};

export default BasicHeader;
